import Swiper, {Autoplay, EffectFade, Pagination} from "swiper";

const [d] = [document];

const domReady = TPL => {
  //メインスライダー
  const mainvisual = new Swiper('.mainvisual [data-plugin="slider"]',{
    modules: [Autoplay, EffectFade, Pagination],
    loop: true,
    effect: 'fade',
    speed: 1400,
    fadeEffect: {
      crossFade: true, // クロスフェードを有効にする（フェードモードの場合 true 推奨）
    },
    autoplay:{
      delay: 4000,
      disableOnInteraction: false,
      waitForTransition: false
    }
  });

};

const jqInit = () => false;

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  
  $(() => jqInit());
}